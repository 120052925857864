import React from "react"

import BusinessCardVariantGrid from "./BusinessCardVariantGrid"
import SendableContentVariantGrid from "./SendableContentVariantGrid"

interface Props {
  sendableContent: Sendable_content.Card | "businessCard"
}

const SendableContentModal: React.FC<Props> = ({ sendableContent }) => {
  if (
    typeof sendableContent === "string" &&
    sendableContent === "businessCard"
  ) {
    return <BusinessCardVariantGrid />
  } else {
    return <SendableContentVariantGrid sendableContent={sendableContent} />
  }
}

export default SendableContentModal
