import React, { useCallback, useState } from "react"
import styled from "styled-components"

import "swiper/css"
import { useNotify } from "../../alert/hooks"
import { useUser } from "../../auth/hooks"
import { verifySession } from "../../auth/utils"
import { Button } from "../../components"
import { validatePhoneNumber } from "../../utils/mobileNumber"

const BusinessCardContainer = styled.div`
  height: 100%;

  color: ${({ theme }) => theme.palette.aquaDeep};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .icon {
    margin-top: 25px;
    margin-bottom: 35px;
    height: 120px;
    align-self: center;
  }
  .card-title {
    font-size: 24px;
    line-height: 22px;
    font-weight: 500;
    margin-bottom: 0px;
    color: ${({ theme }) => theme.palette.aquaDeep};
  }

  .card-description {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 0px;
  }

  button {
    background-color: transparent;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    padding: 9px 20px;
    border-radius: 40px;
    border-width: 2px;
    cursor: pointer;
    transition: background-color 0.2s;
    align-self: flex-end;
    justify-self: flex-end;
  }

  button:hover {
    background-color: ${({ theme }) => `${theme.palette.aquaDeep}20`};
  }

  .download-button {
    width: 50%;
    justify-content: flex-end;
    align-self: flex-end;
  }
`

const EmailSignatureSlide: React.FC = () => {
  const { user } = useUser()
  const sessionToken = verifySession()
  const notify = useNotify()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleDownloadEmailSignature = useCallback(async () => {
    setIsSubmitting(true)
    if (user?.profile?.isProfileActivated) {
      if (!validatePhoneNumber(user?.mobileNumber)) {
        notify(
          "danger",
          "Could not download your email signature",
          "please add a mobile phone number"
        )
      } else {
        const url = `${process.env.GATSBY_YOCO_EMAIL_SIGNATURE_ENDPOINT}`
        await fetch(url, {
          headers: {
            "content-Type": "application/json",
            authorization: sessionToken?.token
              ? `JWT ${sessionToken.token}`
              : "",
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            const _url = window.URL.createObjectURL(blob)
            window.open(_url, "_blank")?.focus()
          })
          .catch(() => {
            notify(
              "danger",
              "Could not download your email signature",
              "please try again later"
            )
          })
      }
    } else {
      notify(
        "danger",
        "Could not download your email signature",
        "complete your profile and sign your contract first"
      )
    }
    setIsSubmitting(false)
  }, [
    user?.profile?.isProfileActivated,
    user?.mobileNumber,
    sessionToken?.token,
    notify,
  ])

  return (
    <BusinessCardContainer>
      <div className="content">
        <img
          className="icon"
          src="/images/sendable-content/simple_payment_logo_green.svg"
          alt="Icon"
        />
        <h1 className="card-title">Your Email Signature</h1>
        <p className="card-description">
          Download a professionally designed Yoco email signature establishing
          your part within the reseller system
        </p>
      </div>
      <Button
        label="Download"
        disabled={isSubmitting}
        loading={isSubmitting}
        color="#014737"
        loaderColor="#014737"
        textColor="#014737"
        borderColor="#014737"
        onClick={handleDownloadEmailSignature}
        className="download-button"
      />
    </BusinessCardContainer>
  )
}

export default EmailSignatureSlide
