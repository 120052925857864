import React, { useCallback, useMemo, useState } from "react"
import Masonry from "react-masonry-css"
import styled from "styled-components"

import Filter, { FilterOption } from "../../components/Filter"

const Wrapper = styled.div`
  margin-top: 50px;

  .filter-container {
    margin-bottom: 20px;
  }

  .variant-title {
    font-size: 22px;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 26px;
    }
  }
`

const VariantCard = styled.div`
  position: relative;
  margin-bottom: 1rem;
  overflow: hidden;
  border-radius: 10px;
  isolation: isolate;

  img {
    border-radius: 10px;
    min-height: 100px;
  }

  .placeholder-thumbnail {
    width: 100%;
    height: 150px;
    background-color: ${({ theme }) => theme.palette.hummingBird};
    object-fit: scale-down;

    & + .card-overlay {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.6);
      &:hover {
        background-color: rgba(0, 0, 0, 0.9);
      }
    }
  }

  .card-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: ${({ theme }) => theme.palette.white};
    transition: 0.2s ease-in-out;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
`

const VariantGroupContainer = styled.div`
  .variant-group-title {
    margin-top: 14px;
  }
`

const MasonryGridContainer = styled.div`
  .masonry-grid {
    display: flex;
    width: auto;
    margin-left: -1rem; /* gutter size offset */
  }
  .masonry-grid_column {
    padding-left: 1rem; /* gutter size */
    background-clip: padding-box;
  }
`

interface Props {
  sendableContent: Sendable_content.Card
}

const SendableContentVariantGrid: React.FC<Props> = ({ sendableContent }) => {
  const [currentFilterIndex, setCurrentFilterIndex] = useState(0)

  const handleFilterChange = useCallback((targetFilterIndex: number) => {
    setCurrentFilterIndex(targetFilterIndex)
  }, [])

  const filterData = useMemo((): FilterOption[] => {
    const filters = sendableContent.sendableContentVariantGroups.map(
      (group) => ({
        title: group.title,
      })
    )

    return filters
  }, [sendableContent])

  const totalVariants = sendableContent.sendableContentVariantGroups[
    currentFilterIndex
  ].sendableContentVariants.filter((variant) => variant.linkUrl).length

  const MASONRY_BREAKPOINTS = {
    default: totalVariants <= 4 ? totalVariants : 4,
    1200: totalVariants <= 3 ? totalVariants : 3,
    768: totalVariants <= 2 ? totalVariants : 2,
    540: totalVariants <= 1 ? totalVariants : 1,
  }

  const useDefaultSrc = (event: any) => {
    if (event?.target?.src) {
      event.target.classList.add("placeholder-thumbnail")
      event.target.src = "/images/yoco-logo.svg"
    }
  }

  return (
    <Wrapper>
      <h2 className="variant-title">{sendableContent.title}</h2>
      <hr />
      {filterData.length > 1 ? (
        <div className="filter-container">
          <Filter
            filterOptions={filterData}
            onClick={handleFilterChange}
            activeIndex={currentFilterIndex}
          />
        </div>
      ) : null}
      <VariantGroupContainer>
        <MasonryGridContainer>
          <Masonry
            breakpointCols={MASONRY_BREAKPOINTS}
            className="masonry-grid"
            columnClassName="masonry-grid_column"
          >
            {sendableContent.sendableContentVariantGroups[
              currentFilterIndex
            ].sendableContentVariants.map((variant, index) =>
              variant.linkUrl ? (
                <VariantCard key={`${variant.title}-${index}`}>
                  <img
                    className="card-image"
                    src={`${
                      variant.thumbnail &&
                      variant.thumbnail.filename !== null &&
                      variant.thumbnail.filename !== ""
                        ? variant.thumbnail.filename
                        : variant.linkUrl
                    }/m/400x`}
                    alt={variant.title}
                    onError={useDefaultSrc}
                  />
                  <a
                    href={variant.linkUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="card-overlay"
                  >
                    <h4>{variant.title}</h4>
                    <p>Click to download</p>
                  </a>
                </VariantCard>
              ) : null
            )}
          </Masonry>
        </MasonryGridContainer>
      </VariantGroupContainer>
    </Wrapper>
  )
}

export default SendableContentVariantGrid
