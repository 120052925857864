import React from "react"
import styled from "styled-components"

import "swiper/css"
import { Card, RoundedButton } from "../../components"

const SliderCard = styled(Card)`
  height: 100%;
`

const Content = styled.div<{
  titleColor: string
  descriptionColor: string
  categoryColor: string
}>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 12px;
  line-height: 20px;

  .content-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .categories {
    align-self: flex-end;
    display: flex;
    gap: 5px;
    margin-bottom: 25px;
    text-align: center;
    font-size: 11px;
    line-height: 12px;

    span {
      background-color: ${({ categoryColor }) => `${categoryColor}4D`};
      color: ${({ theme }) => theme.palette.white};
      padding: 5px 10px;
      border-radius: 12px;
    }
  }

  .icon {
    margin-bottom: 35px;
  }

  .title {
    font-family: ${({ theme }) => theme.fonts.title};
    color: ${({ titleColor }) => titleColor};
    font-size: 30px;
    line-height: 35px;
  }

  .description {
    color: ${({ descriptionColor }) => descriptionColor};
  }

  .view-now-button {
    align-self: flex-end;
  }
`

interface Props {
  card: Sendable_content.Card
  handleViewContent: (card: Sendable_content.Card) => void
}

const SendableContentSlide: React.FC<Props> = ({ card, handleViewContent }) => {
  return (
    <SliderCard backgroundColor={card.backgroundColor} padding="20px">
      <Content
        titleColor={card.titleColor}
        descriptionColor={card.descriptionColor}
        categoryColor={card.categoryColor}
      >
        <div className="content-body">
          <div className="categories">
            {card.category.map((currentCategory) => (
              <span key={`${card._uid}-${currentCategory}`}>
                {currentCategory}
              </span>
            ))}
          </div>
          <img
            className="icon"
            src={card.icon.filename}
            alt={card.icon.alt ? card.icon.alt : "Icon"}
            height="37px"
          />
          <h2 className="title">{card.title}</h2>
          <p className="description">{card.description}</p>
        </div>
        <div className="view-now-button">
          <RoundedButton
            type="button"
            color="transparent"
            textColor="white"
            borderColor="white"
            label="View content"
            onClick={() => handleViewContent(card)}
          />
        </div>
      </Content>
    </SliderCard>
  )
}

export default SendableContentSlide
