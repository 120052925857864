import React, { useEffect } from "react"
import styled from "styled-components"

import "swiper/css"
import { useUser } from "../../auth/hooks"
import { Card } from "../../components"
import { formatMobileNumber } from "../../utils/mobileNumber"
import { YocoLogoVariants } from "../constants/BusinessCardVariants"

const Content = styled.div<{
  themeColor: string
}>`
  height: 180px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${({ themeColor }) => themeColor || "#222222"};
  padding: 10px;
  margin: 0;

  .simple-payment-logo {
    position: absolute;
    height: 80px;
    width: 80px;
    top: 10px;
    right: 10px;
  }

  .card-header {
    font-size: 8px;
    font-weight: 500;
    font-family: "sharp-grotesk-25";
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 0px;
    color: #222222;

    img {
      width: 35px;
    }

    .hidden {
      display: none;
    }

    p {
      margin: 0;
    }
  }

  .card-content {
    font-family: "sharp-grotesk-25";
    padding: 0px;

    .names {
      p {
        margin: 0;
        font-size: 18px;
        line-height: 18px;
        white-space: wrap;
        word-wrap: break-word;
        text-transform: uppercase;
      }

      .first-name {
        max-width: 140px;
      }

      .last-name {
        font-family: "sharp-grotesk-25";
        font-weight: 600;
        margin-bottom: 10px;
      }
    }

    .details {
      p {
        margin: 0 0 10px 0;
        padding: 0;
      }

      .sub-heading {
        font-family: "sharp-grotesk-25";
        font-size: 10px;
        font-weight: 600;
      }

      .contact-info {
        font-family: "sharp-grotesk-25";
        font-size: 8px;
        word-wrap: break-word;
      }
    }
  }
`

interface Props {
  variant: Sendable_content.BusinessCard
  isStatic?: boolean
}

const BusinessCard: React.FC<Props> = ({ variant }) => {
  const { user } = useUser()

  useEffect(() => {
    document
      .getElementById(`yoco-logo-variant`)
      ?.setAttribute(
        "src",
        YocoLogoVariants[variant.yocoLogoVariant - 1]["src"]
      )
  }, [variant])

  return (
    <Card backgroundColor={"#fff"} padding="10px" hasShadow>
      <Content themeColor={variant.themeColor}>
        <div className="simple-payment-logo">
          <img
            src={`/images/sendable-content/simple-payment-logo-${variant.simplePaymentsLogoVariant}.svg`}
            alt="Simple payments"
          />
        </div>
        <div className="card-header">
          {variant.yocoLogoVariant == 1 ? (
            <img
              src="/images/yoco-logo.svg"
              alt="Yoco logo"
              id="yoco-logo-variant"
            />
          ) : (
            <img
              src="/images/yoco-logo-black.svg"
              alt="Yoco logo"
              id="yoco-logo-variant"
            />
          )}
          <p>Certified Reseller</p>
        </div>
        <div className="card-content">
          <div className="names">
            <p className="first-name">{user?.firstName}</p>
            <p className="last-name">{user?.lastName}</p>
          </div>
          <div className="details theme-{{ theme_colour }}">
            <p className="sub-heading">Certified Reseller</p>
            <p className="contact-info">
              {user
                ? formatMobileNumber(user.mobileNumber, "INTERNATIONAL")
                : ""}{" "}
              | {user?.email}
            </p>
          </div>
        </div>
      </Content>
    </Card>
  )
}

export default BusinessCard
