import React, { useCallback, useState } from "react"
import styled from "styled-components"

import { useNotify } from "../../alert/hooks"
import { useUser } from "../../auth/hooks"
import { verifySession } from "../../auth/utils"
import { RoundedButton } from "../../components"
import { validatePhoneNumber } from "../../utils/mobileNumber"
import { BusinessCardVariants } from "../constants/BusinessCardVariants"
import BusinessCard from "./BusinessCard"

const Wrapper = styled.div`
  position: relative;
  margin-top: 50px;
  .profile-pic-notice {
    font-size: 10px;
    color: ${({ theme }) => theme.palette.doveGrey};
    margin-bottom: 10px;
  }

  .variant-title {
    font-size: 22px;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 26px;
    }
  }
`

const CardGrid = styled.div<{
  numColumns: number
}>`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding-bottom: 0px;
  transition: padding-bottom 0.2s;
  padding-bottom: 70px;

  .business-card-wrapper {
    width: 100%;
    justify-self: center;
    border-radius: 20px;
    width: 280px;
    cursor: pointer;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      top: 0px;
      left: 0px;
      width: inherit;
      height: 100%;
      border: 0px solid ${({ theme }) => theme.palette.blue};
      border-radius: 20px;
      transition: all 0.2s;
    }
  }

  .active::after {
    border-width: 5px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: repeat(2, minmax(270px, 1fr));
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: ${({ numColumns }) =>
      `repeat(${numColumns}, minmax(270px, 1fr))`};
  }
`

const Footer = styled.div<{
  selectedColor: string
}>`
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.palette.white};
  padding: 20px 30px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 20px 60px;
  }

  .footer-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    p {
      font-size: 14px;

      @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 16px;
      }
    }
  }

  .selected {
    line-height: 20px;
    font-size: 16px;
    margin-bottom: 0;

    span {
      font-family: ${({ theme }) => theme.fonts.title};
      font-weight: 700;
      color: ${({ selectedColor }) => selectedColor};
    }
  }
`

const BusinessCardVariantGrid: React.FC = () => {
  const sessionToken = verifySession()
  const { user } = useUser()
  const notify = useNotify()

  const [isLoading, setIsLoading] = useState(false)

  const [currentVariant, setCurrentVariant] =
    useState<Sendable_content.BusinessCard | null>(null)

  const handleSelectVariant = (variant: Sendable_content.BusinessCard) => {
    if (currentVariant === variant) {
      setCurrentVariant(null)
    } else {
      setCurrentVariant(variant)
    }
  }

  const handleDownloadBusinessCard = useCallback(async () => {
    if (user?.profile?.isProfileActivated && currentVariant) {
      if (!validatePhoneNumber(user?.mobileNumber)) {
        notify(
          "danger",
          "Could not download your business card",
          "please add a mobile phone number"
        )
      } else if (user?.profile?.profilePicture) {
        setIsLoading(true)
        const url = `${process.env.GATSBY_YOCO_BUSINESS_CARD_ENDPOINT}${currentVariant.label}`
        await fetch(url, {
          headers: {
            "content-Type": "application/json",
            authorization: sessionToken?.token
              ? `JWT ${sessionToken.token}`
              : "",
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            setIsLoading(false)

            const _url = window.URL.createObjectURL(blob)
            window.open(_url, "_blank")?.focus()
          })
          .catch(() => {
            notify(
              "danger",
              "Could not download your business card",
              "please try again later"
            )
          })
      } else {
        notify(
          "danger",
          "Could not download your business card",
          "please upload a profile picture first"
        )
      }
    } else {
      notify(
        "danger",
        "Could not download your business card",
        "complete your profile and sign your contract first"
      )
    }
  }, [
    user?.profile?.isProfileActivated,
    user?.profile?.profilePicture,
    user?.mobileNumber,
    sessionToken?.token,
    currentVariant,
    notify,
  ])

  return (
    <Wrapper>
      <h2 className="variant-title">Pick your business card</h2>
      {!user?.profile?.profilePicture && (
        <p className="profile-pic-notice">
          Please note you will need to upload a profile picture before being
          able to download a business card.
        </p>
      )}
      <hr />
      <CardGrid numColumns={Math.ceil(BusinessCardVariants.length / 2)}>
        {BusinessCardVariants.map((businessCard, index) => (
          <div
            key={`${businessCard.label}-${index}`}
            onClick={() => handleSelectVariant(businessCard)}
            className={
              currentVariant === businessCard
                ? "business-card-wrapper active"
                : "business-card-wrapper"
            }
          >
            <BusinessCard variant={businessCard} isStatic={true} />
          </div>
        ))}
      </CardGrid>
      <Footer selectedColor={currentVariant?.themeColor || "#222222"}>
        <div className="footer-content">
          <p className="selected">
            Selected style:{" "}
            <span>
              {currentVariant !== null ? currentVariant?.label : "none"}
            </span>
          </p>
          <div>
            <RoundedButton
              label="Download"
              type="button"
              color="transparent"
              borderColor="#222222"
              textColor="#222222"
              disabled={currentVariant === null}
              onClick={handleDownloadBusinessCard}
              loading={isLoading}
              loaderColor="#222222"
            />
          </div>
        </div>
      </Footer>
    </Wrapper>
  )
}

export default BusinessCardVariantGrid
