import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import { Card } from "../../components"
import { BusinessCardVariants } from "../constants/BusinessCardVariants"
import BusinessCard from "./BusinessCard"
import EmailSignatureSlide from "./EmailSignatureSlide"
import SendableContentSlide from "./SendableContentSlide"

const Wrapper = styled.div`
  margin-top: 50px;

  .swiper-container {
    max-width: 100vw;
    margin-left: -${({ theme }) => theme.margins.sm};
    margin-right: -${({ theme }) => theme.margins.sm};

    .swiper-slide {
      height: auto;
      width: 310px;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      width: calc(100vw - 300px);
      margin-left: -${({ theme }) => theme.margins.md};
      margin-right: -${({ theme }) => theme.margins.md};
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      width: calc(100vw - 300px);
      margin-left: -${({ theme }) => theme.margins.lg};
      margin-right: -${({ theme }) => theme.margins.lg};
    }
  }
`

const SliderCard = styled(Card)`
  height: 100%;
`

const BusinessCardContainer = styled.div`
  height: 100%;

  color: ${({ theme }) => theme.palette.white};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .card-title {
    font-size: 24px;
    line-height: 22px;
    font-weight: 500;
    margin-bottom: 0px;
    color: ${({ theme }) => theme.palette.white};
  }

  .card-description {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 0px;
  }

  button {
    background-color: transparent;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    padding: 9px 20px;
    border-radius: 40px;
    border: 2px solid ${({ theme }) => theme.palette.white};
    color: inherit;
    cursor: pointer;
    transition: background-color 0.2s;
    align-self: flex-end;
    justify-self: flex-end;
  }

  button:hover {
    background-color: ${({ theme }) => `${theme.palette.white}20`};
  }
`

interface Props {
  showBusinessCard: boolean
  showEmailSignature: boolean
  cards: Sendable_content.Card[]
  handleViewContent: (card: Sendable_content.Card | "businessCard") => void
}

const SendableContentCardSlider: React.FC<Props> = ({
  showBusinessCard,
  showEmailSignature,
  cards,
  handleViewContent,
}) => {
  const [currentVariantIndex, setCurrentVariantIndex] = useState(0)

  const changeThemeColor = useCallback(() => {
    if (currentVariantIndex >= BusinessCardVariants.length - 1) {
      setCurrentVariantIndex(0)
    } else {
      setCurrentVariantIndex(currentVariantIndex + 1)
    }
  }, [currentVariantIndex, setCurrentVariantIndex])

  useEffect(() => {
    const autoChangeThemeColor = setTimeout(changeThemeColor, 3000)

    return () => {
      clearTimeout(autoChangeThemeColor)
    }
  }, [changeThemeColor])

  return (
    <Wrapper>
      <div className="swiper-container">
        <Swiper
          slidesPerView={"auto"}
          slidesOffsetBefore={30}
          spaceBetween={20}
          slidesOffsetAfter={30}
          grabCursor={true}
          breakpoints={{
            1200: {
              slidesOffsetBefore: 65,
              slidesOffsetAfter: 65,
            },
          }}
        >
          {showBusinessCard && (
            <SwiperSlide key="business-card-slide">
              <SliderCard backgroundColor="#00a9e0" padding="20px">
                <BusinessCardContainer>
                  <div className="content">
                    <BusinessCard
                      variant={BusinessCardVariants[currentVariantIndex]}
                    />
                    <h1 className="card-title">Your Business Card</h1>
                    <p className="card-description">
                      This is your business card. Get it printed or download as
                      a digital business card.
                    </p>
                  </div>
                  <button onClick={() => handleViewContent("businessCard")}>
                    View styles
                  </button>
                </BusinessCardContainer>
              </SliderCard>
            </SwiperSlide>
          )}
          {showEmailSignature && (
            <SwiperSlide key="email-signature-slide">
              <SliderCard backgroundColor="#ffffff" padding="20px">
                <EmailSignatureSlide />
              </SliderCard>
            </SwiperSlide>
          )}
          {cards.map((card) => (
            <SwiperSlide key={card._uid}>
              <SendableContentSlide
                card={card}
                handleViewContent={handleViewContent}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Wrapper>
  )
}

export default SendableContentCardSlider
