import { graphql, useStaticQuery } from "gatsby"
import { useMemo } from "react"

export const useSendableContent = (): Sendable_content.Card[] => {
  const sendableContentData: Storyblok.StoryBlokData = useStaticQuery(graphql`
    query sendableContent {
      allStoryblokEntry(
        filter: { full_slug: { glob: "sendable-content/*" } }
        sort: { fields: id }
      ) {
        edges {
          node {
            uuid
            name
            slug
            content
          }
        }
      }
    }
  `)

  const cardsData: Sendable_content.Card[] = useMemo(
    () =>
      sendableContentData.allStoryblokEntry.edges.map((module) =>
        JSON.parse(module.node.content)
      ),
    [sendableContentData.allStoryblokEntry.edges]
  )

  return cardsData
}
