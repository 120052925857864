export const BusinessCardVariants: Sendable_content.BusinessCard[] = [
  {
    label: "green",
    themeColor: "#015944",
    yocoLogoVariant: 1,
    simplePaymentsLogoVariant: 1,
  },
  {
    label: "black",
    themeColor: "#222222",
    yocoLogoVariant: 2,
    simplePaymentsLogoVariant: 2,
  },
]

export const YocoLogoVariants = [
  {
    id: 1,
    src: "/images/yoco-logo.svg",
  },
  {
    id: 2,
    src: "/images/yoco-logo-black.svg",
  },
]
