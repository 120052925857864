import React, { useState, useCallback, useMemo } from "react"
import styled from "styled-components"

import {
  Filter,
  Modal,
  PageHeader,
  PageGuard,
  PageNotificationBanner,
} from "../../components"
import { FilterOption } from "../../components/Filter"
import Seo from "../../components/seo"
import SendableContentCardSlider from "../../sendable-content/components/SendableContentCardSlider"
import SendableContentModal from "../../sendable-content/components/SendableContentModal"
import { useSendableContent } from "../../sendable-content/hooks"

const CardGrid = styled.div`
  width: 100%;

  .header {
    margin-bottom: 20px;
  }

  .grid-notification {
    margin-bottom: 30px;

    .link {
      color: ${({ theme }) => theme.palette.orangePeel};
      font-weight: 500;
    }

    p {
      margin-bottom: 0;
    }
  }

  .card-slider {
    margin-bottom: 50px;
  }
`

const NoContentMessage = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.palette.white};
  border-radius: 20px;
  padding: 20px;
  margin-top: 50px;

  p {
    font-size: 12px;
    color: ${({ theme }) => theme.palette.mineShaft};
    margin: 0;
  }
`

const SendableContent = (): React.ReactElement => {
  const sendableContent = useSendableContent()
  const groupedSendableContent = sendableContent.reduce(
    (
      result: Record<string, Sendable_content.Card[]>,
      card: Sendable_content.Card
    ) => {
      card.category.forEach((currentCategory) => {
        if (Object.keys(result).includes(currentCategory)) {
          result[currentCategory].push(card)
        } else {
          result[currentCategory] = [card]
        }
      })
      return result
    },
    {}
  )

  const [currentFilterIndex, setCurrentFilterIndex] = useState(0)
  const [modalShowing, setModalShowing] = useState(false)
  const [selectedContent, setSelectedContent] = useState<
    Sendable_content.Card | null | "businessCard"
  >()

  const filterData = useMemo((): FilterOption[] => {
    const keys = Object.keys(groupedSendableContent)
    const filters = keys.map((key) => ({
      title: key,
    }))
    filters.unshift({
      title: "All Content",
    })
    return filters
  }, [groupedSendableContent])

  const handleFilterChange = useCallback((targetFilterIndex: number) => {
    setCurrentFilterIndex(targetFilterIndex)
  }, [])

  const showSelectedContentModal = (
    card: Sendable_content.Card | "businessCard"
  ) => {
    setSelectedContent(card)
    setModalShowing(true)
  }

  return (
    <PageGuard verifyUserProfile>
      <Seo title="Sendable Content" />
      <CardGrid>
        <div className="header">
          <PageHeader
            cypressTestId="sendable-content-page-header"
            title="Sendable Content"
          />
        </div>
        <PageNotificationBanner>
          Send useful information about Yoco products and services form the list
          below.
        </PageNotificationBanner>
        <div className="module-slider">
          <Filter
            isPageFilter
            filterOptions={filterData}
            onClick={handleFilterChange}
            activeIndex={currentFilterIndex}
          />
        </div>
        <div className="card-slider">
          {sendableContent ? (
            <SendableContentCardSlider
              key={`module-${currentFilterIndex}`}
              showBusinessCard={currentFilterIndex === 0}
              showEmailSignature={currentFilterIndex === 0}
              cards={
                currentFilterIndex === 0
                  ? sendableContent
                  : groupedSendableContent[filterData[currentFilterIndex].title]
              }
              handleViewContent={showSelectedContentModal}
            />
          ) : (
            <NoContentMessage>
              <p>
                There is currently no sendable content available, check back
                soon for new content.
              </p>
            </NoContentMessage>
          )}
        </div>
      </CardGrid>
      <Modal open={modalShowing} onClose={() => setModalShowing(false)}>
        {selectedContent ? (
          <SendableContentModal sendableContent={selectedContent} />
        ) : null}
      </Modal>
    </PageGuard>
  )
}

export default SendableContent
